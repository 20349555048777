import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cbd9e4fc = () => interopDefault(import('../src/pages/foo.vue' /* webpackChunkName: "pages/foo" */))
const _6688ca9c = () => interopDefault(import('../src/pages/ie/index.js' /* webpackChunkName: "pages/ie/index" */))
const _3996d62b = () => interopDefault(import('../src/pages/ie/ie.vue' /* webpackChunkName: "pages/ie/ie" */))
const _1b539d6e = () => interopDefault(import('../src/modules/admin/pages/index' /* webpackChunkName: "" */))
const _43dd1e44 = () => interopDefault(import('../src/modules/angels/pages/frontend/index' /* webpackChunkName: "" */))
const _178e172e = () => interopDefault(import('../src/modules/angels/pages/frontend/thank-you' /* webpackChunkName: "" */))
const _3bf96eaa = () => interopDefault(import('../src/modules/appeals/pages/frontend/show' /* webpackChunkName: "" */))
const _f19e8108 = () => interopDefault(import('../src/modules/auth/pages/register' /* webpackChunkName: "" */))
const _0ed9b530 = () => interopDefault(import('../src/modules/auth/pages/login' /* webpackChunkName: "" */))
const _64b3ab0c = () => interopDefault(import('../src/modules/auth/pages/forgot-password' /* webpackChunkName: "" */))
const _65267372 = () => interopDefault(import('../src/modules/auth/pages/reset-password' /* webpackChunkName: "" */))
const _02c714e0 = () => interopDefault(import('../src/modules/basket/pages/frontend/index' /* webpackChunkName: "" */))
const _551bc325 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/basket' /* webpackChunkName: "" */))
const _33495487 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment' /* webpackChunkName: "" */))
const _1e39b2fc = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment-details' /* webpackChunkName: "" */))
const _2ae883ac = () => interopDefault(import('../src/modules/basket/pages/frontend/index/login' /* webpackChunkName: "" */))
const _baa0d68e = () => interopDefault(import('../src/modules/basket/pages/frontend/index/receipt' /* webpackChunkName: "" */))
const _1faf2228 = () => interopDefault(import('../src/modules/cms/pages/index' /* webpackChunkName: "" */))
const _51763b00 = () => interopDefault(import('../src/modules/cms/pages/news/index' /* webpackChunkName: "" */))
const _5dd729b6 = () => interopDefault(import('../src/modules/cms/pages/news/item' /* webpackChunkName: "" */))
const _7264cd0c = () => interopDefault(import('../src/modules/donations/pages/frontend/donate' /* webpackChunkName: "" */))
const _46456ece = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/index' /* webpackChunkName: "" */))
const _96710bd6 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/create' /* webpackChunkName: "" */))
const _6a36b963 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/edit' /* webpackChunkName: "" */))
const _052fd390 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/select-mfi' /* webpackChunkName: "" */))
const _672acc6e = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-index' /* webpackChunkName: "" */))
const _c2922eb2 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-mfi' /* webpackChunkName: "" */))
const _52cc9a17 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-index' /* webpackChunkName: "" */))
const _d2ceb716 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-mfi' /* webpackChunkName: "" */))
const _88388e48 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/index' /* webpackChunkName: "" */))
const _0e5621b3 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/show' /* webpackChunkName: "" */))
const _9774a2c4 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _9d24d368 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/archive' /* webpackChunkName: "" */))
const _67c4e21c = () => interopDefault(import('../src/modules/grants/pages/frontend/home' /* webpackChunkName: "" */))
const _4d28c6fb = () => interopDefault(import('../src/modules/grants/pages/frontend/search' /* webpackChunkName: "" */))
const _67bb15e0 = () => interopDefault(import('../src/modules/grants/pages/frontend/show' /* webpackChunkName: "" */))
const _4e2b6bc8 = () => interopDefault(import('../src/modules/grants/pages/admin/index' /* webpackChunkName: "" */))
const _81d72980 = () => interopDefault(import('../src/modules/grants/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _6d3dbcc6 = () => interopDefault(import('../src/modules/grants/pages/admin/create' /* webpackChunkName: "" */))
const _340ff454 = () => interopDefault(import('../src/modules/grants/pages/admin/edit' /* webpackChunkName: "" */))
const _1138235f = () => interopDefault(import('../src/modules/homepage/pages/index' /* webpackChunkName: "" */))
const _18cef915 = () => interopDefault(import('../src/modules/loans/pages/frontend/search' /* webpackChunkName: "" */))
const _100265aa = () => interopDefault(import('../src/modules/loans/pages/frontend/show' /* webpackChunkName: "" */))
const _5aba8a3c = () => interopDefault(import('../src/modules/loans/pages/admin/index' /* webpackChunkName: "" */))
const _7d5e44da = () => interopDefault(import('../src/modules/loans/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _77b050ec = () => interopDefault(import('../src/modules/loans/pages/admin/create' /* webpackChunkName: "" */))
const _13754c0c = () => interopDefault(import('../src/modules/loans/pages/admin/edit' /* webpackChunkName: "" */))
const _76591588 = () => interopDefault(import('../src/modules/loans/pages/admin/messages' /* webpackChunkName: "" */))
const _5ab1c974 = () => interopDefault(import('../src/modules/marketing/pages/frontend/shop' /* webpackChunkName: "" */))
const _4cca0e4e = () => interopDefault(import('../src/modules/marketing/pages/frontend/welcome-answer' /* webpackChunkName: "" */))
const _4cb92e98 = () => interopDefault(import('../src/modules/mfis/pages/admin/index' /* webpackChunkName: "" */))
const _9722ac30 = () => interopDefault(import('../src/modules/mfis/pages/admin/edit' /* webpackChunkName: "" */))
const _4c9a2362 = () => interopDefault(import('../src/modules/mfis/pages/admin/admins' /* webpackChunkName: "" */))
const _43402553 = () => interopDefault(import('../src/modules/mfis/pages/admin/mfi-info-messages/index' /* webpackChunkName: "" */))
const _7e085d69 = () => interopDefault(import('../src/modules/mfis/pages/admin/mfi-info-messages/edit' /* webpackChunkName: "" */))
const _54a583f2 = () => interopDefault(import('../src/modules/offsetting/pages/admin/index' /* webpackChunkName: "" */))
const _20e8f3e2 = () => interopDefault(import('../src/modules/offsetting/pages/admin/archive' /* webpackChunkName: "" */))
const _13439bdd = () => interopDefault(import('../src/modules/offsetting/pages/admin/show' /* webpackChunkName: "" */))
const _ce1cee98 = () => interopDefault(import('../src/modules/offsetting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _1e6d11fc = () => interopDefault(import('../src/modules/referrals/pages/frontend/make-referral' /* webpackChunkName: "" */))
const _78c35b86 = () => interopDefault(import('../src/modules/referrals/pages/frontend/referral' /* webpackChunkName: "" */))
const _66dc62de = () => interopDefault(import('../src/modules/repayments/pages/admin/index' /* webpackChunkName: "" */))
const _4eed12c1 = () => interopDefault(import('../src/modules/repayments/pages/admin/archive' /* webpackChunkName: "" */))
const _fe0d33ae = () => interopDefault(import('../src/modules/reporting/pages/reporting' /* webpackChunkName: "" */))
const _e918928a = () => interopDefault(import('../src/modules/reporting/pages/reporting/donations' /* webpackChunkName: "" */))
const _3680b789 = () => interopDefault(import('../src/modules/reporting/pages/reporting/reconciliation' /* webpackChunkName: "" */))
const _2d900ce7 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-changes' /* webpackChunkName: "" */))
const _40831806 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-data' /* webpackChunkName: "" */))
const _107b5c32 = () => interopDefault(import('../src/modules/reporting/pages/reporting/gift-aid-data' /* webpackChunkName: "" */))
const _1f4bdf3a = () => interopDefault(import('../src/modules/reporting/pages/reporting/entrepreneur-and-loan-data' /* webpackChunkName: "" */))
const _182acc08 = () => interopDefault(import('../src/modules/site-settings/pages/index' /* webpackChunkName: "" */))
const _b394539e = () => interopDefault(import('../src/modules/site-settings/pages/index/loan-activity-types' /* webpackChunkName: "" */))
const _2a2ea25d = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources' /* webpackChunkName: "" */))
const _15225110 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/list' /* webpackChunkName: "" */))
const _46eeecee = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/create' /* webpackChunkName: "" */))
const _151f0e7c = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/edit' /* webpackChunkName: "" */))
const _8b782748 = () => interopDefault(import('../src/modules/teams/pages/frontend/create' /* webpackChunkName: "" */))
const _6b2e2e76 = () => interopDefault(import('../src/modules/teams/pages/frontend/manage' /* webpackChunkName: "" */))
const _eb141d1c = () => interopDefault(import('../src/modules/teams/pages/frontend/index' /* webpackChunkName: "" */))
const _3e79aa49 = () => interopDefault(import('../src/modules/teams/pages/frontend/profile' /* webpackChunkName: "" */))
const _97fd56c4 = () => interopDefault(import('../src/modules/updates/pages/frontend/index' /* webpackChunkName: "" */))
const _0e1505b1 = () => interopDefault(import('../src/modules/updates/pages/frontend/show' /* webpackChunkName: "" */))
const _0b1bb0a9 = () => interopDefault(import('../src/modules/updates/pages/admin/index' /* webpackChunkName: "" */))
const _e4630c90 = () => interopDefault(import('../src/modules/updates/pages/admin/select-loan' /* webpackChunkName: "" */))
const _4e561405 = () => interopDefault(import('../src/modules/updates/pages/admin/create' /* webpackChunkName: "" */))
const _b4f9e15a = () => interopDefault(import('../src/modules/updates/pages/admin/edit' /* webpackChunkName: "" */))
const _59633827 = () => interopDefault(import('../src/modules/users/pages/admin/index' /* webpackChunkName: "" */))
const _671eac02 = () => interopDefault(import('../src/modules/users/pages/admin/index/user-search' /* webpackChunkName: "" */))
const _4cee479c = () => interopDefault(import('../src/modules/users/pages/admin/index/re-bulk-update' /* webpackChunkName: "" */))
const _5eda542f = () => interopDefault(import('../src/modules/users/pages/admin/index/bulk-create' /* webpackChunkName: "" */))
const _7cf5e8ec = () => interopDefault(import('../src/modules/users/pages/admin/mfi-index' /* webpackChunkName: "" */))
const _8ed5c454 = () => interopDefault(import('../src/modules/users/pages/admin/user' /* webpackChunkName: "" */))
const _4907d6a3 = () => interopDefault(import('../src/modules/users/pages/admin/user/edit' /* webpackChunkName: "" */))
const _d46e7a02 = () => interopDefault(import('../src/modules/users/pages/admin/user/gift-aid-history' /* webpackChunkName: "" */))
const _a0130f3e = () => interopDefault(import('../src/modules/users/pages/admin/user/email-change-history' /* webpackChunkName: "" */))
const _a912ff40 = () => interopDefault(import('../src/modules/users/pages/admin/transactions' /* webpackChunkName: "" */))
const _59e29e12 = () => interopDefault(import('../src/modules/users/pages/admin/roles' /* webpackChunkName: "" */))
const _8edd6ae4 = () => interopDefault(import('../src/modules/users/pages/admin/mfis' /* webpackChunkName: "" */))
const _6e00f378 = () => interopDefault(import('../src/modules/users/pages/admin/credit' /* webpackChunkName: "" */))
const _1dbe8c3e = () => interopDefault(import('../src/modules/users/pages/frontend/my-account' /* webpackChunkName: "" */))
const _c347a858 = () => interopDefault(import('../src/modules/users/pages/frontend/my-loans' /* webpackChunkName: "" */))
const _24cb5aa6 = () => interopDefault(import('../src/modules/users/pages/frontend/my-grants' /* webpackChunkName: "" */))
const _8f42f0d8 = () => interopDefault(import('../src/modules/users/pages/frontend/my-vouchers' /* webpackChunkName: "" */))
const _522229e4 = () => interopDefault(import('../src/modules/users/pages/frontend/my-transactions' /* webpackChunkName: "" */))
const _141b52d7 = () => interopDefault(import('../src/modules/users/pages/frontend/add-credit' /* webpackChunkName: "" */))
const _233b45ac = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit' /* webpackChunkName: "" */))
const _bc380820 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/index/index' /* webpackChunkName: "" */))
const _3797a8b4 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/completed' /* webpackChunkName: "" */))
const _23df2c92 = () => interopDefault(import('../src/modules/users/pages/frontend/profile' /* webpackChunkName: "" */))
const _2edb8e88 = () => interopDefault(import('../src/modules/users/pages/frontend/edit' /* webpackChunkName: "" */))
const _775df0e6 = () => interopDefault(import('../src/modules/users/pages/frontend/close-account' /* webpackChunkName: "" */))
const _4efa911e = () => interopDefault(import('../src/modules/vouchers/pages/frontend/index' /* webpackChunkName: "" */))
const _0f3cb7ed = () => interopDefault(import('../src/modules/vouchers/pages/frontend/free' /* webpackChunkName: "" */))
const _46b0fedd = () => interopDefault(import('../src/modules/vouchers/pages/frontend/redeem' /* webpackChunkName: "" */))
const _5968caf3 = () => interopDefault(import('../src/modules/vouchers/pages/admin' /* webpackChunkName: "" */))
const _4b241f68 = () => interopDefault(import('../src/modules/vouchers/pages/admin/listing' /* webpackChunkName: "" */))
const _4e4b999a = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases' /* webpackChunkName: "" */))
const _b06d70b0 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/listing' /* webpackChunkName: "" */))
const _515cdf58 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/create' /* webpackChunkName: "" */))
const _490a01b6 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/completed' /* webpackChunkName: "" */))
const _73f4ba4e = () => interopDefault(import('../src/modules/withdrawals/pages/admin/requested' /* webpackChunkName: "" */))
const _e81630da = () => interopDefault(import('../src/modules/withdrawals/pages/admin/batches' /* webpackChunkName: "" */))
const _770b73c2 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/pending' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/foo",
    component: _cbd9e4fc,
    name: "foo"
  }, {
    path: "/ie",
    component: _6688ca9c,
    name: "ie"
  }, {
    path: "/ie/ie",
    component: _3996d62b,
    name: "ie-ie"
  }, {
    path: "/admin",
    component: _1b539d6e,
    name: "admin:index"
  }, {
    path: "/angels",
    component: _43dd1e44,
    name: "angels:frontend:index"
  }, {
    path: "/angels/thank-you",
    component: _178e172e,
    name: "angels:frontend:thank-you"
  }, {
    path: "/appeals/:appealSlug",
    component: _3bf96eaa,
    name: "appeals:frontend:show"
  }, {
    path: "/register/:referralCode?",
    component: _f19e8108,
    name: "auth:register"
  }, {
    path: "/login",
    component: _0ed9b530,
    name: "auth:login"
  }, {
    path: "/forgot-password",
    component: _64b3ab0c,
    name: "auth:forgot-password"
  }, {
    path: "/reset-password",
    component: _65267372,
    name: "auth:reset-password"
  }, {
    path: "/basket",
    component: _02c714e0,
    children: [{
      path: "",
      component: _551bc325,
      name: "basket:frontend:index"
    }, {
      path: "payment",
      component: _33495487,
      name: "basket:frontend:payment"
    }, {
      path: "payment-details",
      component: _1e39b2fc,
      name: "basket:frontend:payment-details"
    }, {
      path: "login",
      component: _2ae883ac,
      name: "basket:frontend:login"
    }, {
      path: "receipt/:uuid",
      component: _baa0d68e,
      name: "basket:frontend:receipt"
    }]
  }, {
    path: "/info",
    component: _1faf2228
  }, {
    path: "/info/:uri*",
    component: _1faf2228,
    name: "cms:article"
  }, {
    path: "/news/latest",
    component: _51763b00,
    name: "cms:news"
  }, {
    path: "/news/post/:id",
    component: _5dd729b6,
    name: "cms:news-item"
  }, {
    path: "/donate",
    component: _7264cd0c,
    name: "donations:frontend:donate"
  }, {
    path: "/admin/mfis/entrepreneurs",
    component: _46456ece,
    name: "entrepreneurs:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/create",
    component: _96710bd6,
    name: "entrepreneurs:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)",
    component: _6a36b963,
    name: "entrepreneurs:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/select-mfi",
    component: _052fd390,
    name: "entrepreneurs:admin:select-mfi"
  }, {
    path: "/admin/grant-repayments/mfis/due",
    component: _672acc6e,
    name: "grant-repayments:admin:mfis:due:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/due",
    component: _c2922eb2,
    name: "grant-repayments:admin:mfis:due:mfi"
  }, {
    path: "/admin/grant-repayments/mfis/archive",
    component: _52cc9a17,
    name: "grant-repayments:admin:archive:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/archive",
    component: _d2ceb716,
    name: "grant-repayments:admin:archive:mfi"
  }, {
    path: "/admin/mfis/grant-reports",
    component: _88388e48,
    name: "grantReporting:admin:current"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/:reportId(\\d+)",
    component: _0e5621b3,
    name: "grantReporting:admin:show"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/no-current-report",
    component: _9774a2c4,
    name: "grantReporting:admin:no-current-report"
  }, {
    path: "/admin/mfis/grant-reports/archive",
    component: _9d24d368,
    name: "grantReporting:admin:archive"
  }, {
    path: "/grants",
    component: _67c4e21c,
    name: "grants:frontend:home"
  }, {
    path: "/grants/search",
    component: _4d28c6fb,
    name: "grants:frontend:search"
  }, {
    path: "/grants/:grantId(\\d+)",
    component: _67bb15e0,
    name: "grants:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/grants",
    component: _4e2b6bc8,
    name: "grants:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/grants/select-entrepreneur",
    component: _81d72980,
    name: "grants:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/create",
    component: _6d3dbcc6,
    name: "grants:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/:grantId(\\d+)",
    component: _340ff454,
    name: "grants:admin:edit"
  }, {
    path: "/",
    component: _1138235f,
    name: "homepage:index"
  }, {
    path: "/search",
    component: _18cef915,
    name: "loans:frontend:search"
  }, {
    path: "/loans/:loanId(\\d+)",
    component: _100265aa,
    name: "loans:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans",
    component: _5aba8a3c,
    name: "loans:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/select-entrepreneur",
    component: _7d5e44da,
    name: "loans:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/create",
    component: _77b050ec,
    name: "loans:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)",
    component: _13754c0c,
    name: "loans:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/messages",
    component: _76591588,
    name: "loans:admin:messages:index"
  }, {
    path: "/info/about-us/lendwithcare-shop",
    component: _5ab1c974,
    name: "marketing:frontend:shop"
  }, {
    path: "/welcome-answer/:answer",
    component: _4cca0e4e,
    name: "marketing:user-journey:answer"
  }, {
    path: "/admin/mfis",
    component: _4cb92e98,
    name: "mfis:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)",
    component: _9722ac30,
    name: "mfis:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/administrators",
    component: _4c9a2362,
    name: "mfis:admin:administrators"
  }, {
    path: "/admin/mfis/mfi-info-messages",
    component: _43402553,
    name: "mfis:admin:mfiInfoMessages"
  }, {
    path: "/admin/mfis/mfi-info-messages/:mfiInfoMessageId(\\d+)",
    component: _7e085d69,
    name: "mfis:admin:editMfiInfoMessages"
  }, {
    path: "/admin/mfis/offset-reports",
    component: _54a583f2,
    name: "offsetting:admin:current"
  }, {
    path: "/admin/mfis/offset-reports/archive",
    component: _20e8f3e2,
    name: "offsetting:admin:archive"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/:offsetReportId(\\d+)",
    component: _13439bdd,
    name: "offsetting:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/no-current-report",
    component: _ce1cee98,
    name: "offsetting:admin:no-current-report"
  }, {
    path: "/my-account/make-referral",
    component: _1e6d11fc,
    name: "referral:frontend:make-referral"
  }, {
    path: "/referral/:referralCode?",
    component: _78c35b86,
    name: "referral:frontend:referral"
  }, {
    path: "/admin/repayments",
    component: _66dc62de,
    name: "repayments:admin:index"
  }, {
    path: "/admin/repayments/archive",
    component: _4eed12c1,
    name: "repayments:admin:archive"
  }, {
    path: "/admin/reporting",
    component: _fe0d33ae,
    children: [{
      path: "donations",
      component: _e918928a,
      name: "reporting:donations"
    }, {
      path: "reconciliation",
      component: _3680b789,
      name: "reporting:reconciliation"
    }, {
      path: "user-changes",
      component: _2d900ce7,
      name: "reporting:user-changes-download"
    }, {
      path: "user-data",
      component: _40831806,
      name: "reporting:user-data-download"
    }, {
      path: "gift-aid",
      component: _107b5c32,
      name: "reporting:gift-aid-data-download"
    }, {
      path: "entrepreneur-and-loan-data",
      component: _1f4bdf3a,
      name: "reporting:entrepreneur-and-loan-data-download"
    }]
  }, {
    path: "/admin/site-settings",
    component: _182acc08,
    name: "site-settings:index",
    children: [{
      path: "/admin/site-settings/loan-activity-types",
      component: _b394539e,
      name: "site-settings:loan-activity-types"
    }, {
      path: "/admin/site-settings/user-sources",
      component: _2a2ea25d,
      children: [{
        path: "",
        component: _15225110,
        name: "site-settings:user-sources:list"
      }, {
        path: "create",
        component: _46eeecee,
        name: "site-settings:user-sources:create"
      }, {
        path: ":userSourceId(\\d+)",
        component: _151f0e7c,
        name: "site-settings:user-sources:edit"
      }]
    }]
  }, {
    path: "/my-account/teams/create",
    component: _8b782748,
    name: "teams:frontend:create"
  }, {
    path: "/my-account/teams/:uuid",
    component: _6b2e2e76,
    name: "teams:frontend:manage"
  }, {
    path: "/teams",
    component: _eb141d1c,
    name: "teams:frontend:index"
  }, {
    path: "/teams/:slug",
    component: _3e79aa49,
    name: "teams:frontend:profile"
  }, {
    path: "/updates",
    component: _97fd56c4,
    name: "updates:frontend:index"
  }, {
    path: "/updates/:updateId(\\d+)",
    component: _0e1505b1,
    name: "updates:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates",
    component: _0b1bb0a9,
    name: "updates:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates/create/select-loan",
    component: _e4630c90,
    name: "updates:admin:select-loan"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/create",
    component: _4e561405,
    name: "updates:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/:updateId(\\d+)",
    component: _b4f9e15a,
    name: "updates:admin:edit"
  }, {
    path: "/admin/users",
    component: _59633827,
    children: [{
      path: "",
      component: _671eac02,
      name: "users:admin:index"
    }, {
      path: "raisers-edge",
      component: _4cee479c,
      name: "users:admin:re-bulk-update"
    }, {
      path: "bulk-create",
      component: _5eda542f,
      name: "users:admin:bulk-create"
    }]
  }, {
    path: "/admin/mfi-users",
    component: _7cf5e8ec,
    name: "users:admin:mfi-index"
  }, {
    path: "/admin/users/:userId(\\d+)",
    component: _8ed5c454,
    children: [{
      path: "",
      component: _4907d6a3,
      name: "users:admin:edit"
    }, {
      path: "gift-aid-history",
      component: _d46e7a02,
      name: "users:admin:gift-aid-history"
    }, {
      path: "email-change-history",
      component: _a0130f3e,
      name: "users:admin:email-change-history"
    }]
  }, {
    path: "/admin/users/:userId(\\d+)/transactions",
    component: _a912ff40,
    name: "users:admin:transactions"
  }, {
    path: "/admin/users/:userId(\\d+)/roles",
    component: _59e29e12,
    name: "users:admin:roles"
  }, {
    path: "/admin/users/:userId(\\d+)/mfis",
    component: _8edd6ae4,
    name: "users:admin:mfis"
  }, {
    path: "/admin/users/:userId(\\d+)/credit",
    component: _6e00f378,
    name: "users:admin:credit"
  }, {
    path: "/my-account",
    component: _1dbe8c3e,
    name: "users:frontend:my-account"
  }, {
    path: "/my-account/my-loans",
    component: _c347a858,
    name: "users:frontend:my-loans"
  }, {
    path: "/my-account/my-grants",
    component: _24cb5aa6,
    name: "users:frontend:my-grants"
  }, {
    path: "/my-account/my-gift-vouchers",
    component: _8f42f0d8,
    name: "users:frontend:my-vouchers"
  }, {
    path: "/my-account/my-transactions",
    component: _522229e4,
    name: "users:frontend:my-transactions"
  }, {
    path: "/my-account/add-credit",
    component: _141b52d7,
    name: "users:frontend:add-credit"
  }, {
    path: "/my-account/withdraw-credit",
    component: _233b45ac,
    children: [{
      path: "",
      component: _bc380820,
      name: "users:frontend:withdraw-credit"
    }, {
      path: "completed",
      component: _3797a8b4,
      name: "users:frontend:withdrawal-complete"
    }]
  }, {
    path: "/profile/:userUuid",
    component: _23df2c92,
    name: "users:frontend:profile"
  }, {
    path: "/my-account/my-details",
    component: _2edb8e88,
    name: "users:frontend:edit-details"
  }, {
    path: "/my-account/my-profile",
    component: _2edb8e88,
    name: "users:frontend:edit-profile"
  }, {
    path: "/my-account/my-settings",
    component: _2edb8e88,
    name: "users:frontend:edit-settings"
  }, {
    path: "/my-account/close/:token",
    component: _775df0e6,
    name: "users:frontend:close-account"
  }, {
    path: "/gift-vouchers",
    component: _4efa911e,
    name: "vouchers:frontend:index"
  }, {
    path: "/gift-vouchers/claim-free",
    component: _0f3cb7ed,
    name: "vouchers:frontend:free"
  }, {
    path: "/gift-vouchers/redeem",
    component: _46b0fedd,
    name: "vouchers:frontend:redeem"
  }, {
    path: "/admin/vouchers",
    component: _5968caf3,
    children: [{
      path: "",
      component: _4b241f68,
      name: "vouchers:admin:list"
    }, {
      path: "/admin/vouchers/bulk-purchases",
      component: _4e4b999a,
      children: [{
        path: "",
        component: _b06d70b0,
        name: "vouchers:admin:bulk-purchases"
      }, {
        path: "new",
        component: _515cdf58,
        name: "vouchers:admin:create-bulk-purchase"
      }]
    }]
  }, {
    path: "/admin/withdrawals/completed",
    component: _490a01b6,
    name: "withdrawals:admin:completed"
  }, {
    path: "/admin/withdrawals/requested",
    component: _73f4ba4e,
    name: "withdrawals:admin:requested"
  }, {
    path: "/admin/withdrawals/batches",
    component: _e81630da,
    name: "withdrawals:admin:batches"
  }, {
    path: "/admin/withdrawals/pending",
    component: _770b73c2,
    name: "withdrawals:admin:pending"
  }],

  parseQuery: function (queryString) {
      return window.qs.parse(queryString, {
        allowDots: true,
        arrayFormat: 'brackets',
        ignoreQueryPrefix: true
      });
    },
  stringifyQuery: function (queryObject) {
      return window.qs.stringify(queryObject, {
        addQueryPrefix: true,
        allowDots: true,
        arrayFormat: 'brackets',
        encode: false
      });
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
